import MessageFormat from "@messageformat/core";

import { Language, Store, Translations, Translation } from "./types";

import { flatten } from "./util";

export * from "./frameworkUtils";

const store: Store = {};
const subscribers: Array<() => void> = [];

// enable switching language without reloading the page.
// might work, but requires all page components to subscribe to language switch callbacks, which I don't think they do
// used to be feature toggle.
// implement as unleash toggle if this becomes relevant again in the future,
// or delete if we want to clean up the code.
const instantI18nEnabled = () => false;

export { instantI18nEnabled };

export const i18n = (
  translations: Translations
): ((key: string, data?: Record<string, string | number | Translation>) => string) => {
  addTranslations(translations);
  return translate;
};

export const translate = (
  input: string,
  data?: Record<string, string | number | Translation>
): string => {
  const mf = new MessageFormat(currentLanguage());

  // to stay backwards compatible, if we detect no {}'s, we will insert those automatically.
  const recurs = (str) => {
    const translate = mf.compile(str);
    if (/[{}]/.test(str)) {
      // we are using the modern way to interpolate
      const translated = store[currentLanguage()]
        ? translate({ ...store[currentLanguage()], ...data })
        : str;
      return translated !== str ? recurs(translated) : str;
    } else {
      return str;
    }
  };

  if (!/[{}]/.test(input)) {
    // we are using the old-fashioned way, so we need to convert a few things
    input = `{ ${input.replace(/\./g, "__")} }`;
  }

  return recurs(input);
};

export const addTranslations = (translations: Translations): void => {
  Object.keys(translations).forEach((lang: string) => {
    store[lang] = store[lang] || {};
    Object.assign(store[lang], flatten(translations[lang]));
  });
};

export const addTranslation = (translation: Translation, locale: "SV_SE" | "EN_US"): void => {
  const lang = locale === "SV_SE" ? "sv" : "en";
  store[lang] = store[lang] || {};
  Object.assign(store[lang], flatten(translation));
  callSubscriberCallbacks();
};

export const setCurrentLanguage = (language: Language, useCallback?: boolean): void => {
  let expiryDate = new Date();
  // If it's a leap year, set the expiry date to March 1
  if (expiryDate.getMonth() === 1 && expiryDate.getDate() === 29) {
    expiryDate.setDate(1);
    expiryDate.setMonth(2);
  }
  expiryDate.setFullYear(expiryDate.getFullYear() + 1);
  document.cookie = `SelectedLanguage=${language};path=/;expires=${expiryDate}`;

  if (useCallback && instantI18nEnabled()) {
    callSubscriberCallbacks();
  }
};

export const currentLanguage = (): string => {
  const lang = (
    document.cookie.split(";").find((item) => item.trim().startsWith("SelectedLanguage")) ||
    "SelectedLanguage=sv"
  )
    .trim()
    .split("=")[1];

  return lang;
};

export const currentLocale = (): "SV_SE" | "EN_US" => {
  return currentLanguage() === "sv" ? "SV_SE" : "EN_US";
};

export const subscribe = (callback: () => void): void => {
  subscribers.push(callback);
};

const callSubscriberCallbacks = () => {
  subscribers.forEach((callbackFn) => {
    callbackFn();
  });
};
